<template>
  <v-responsive>
    <SubMenuAnalysisMobile
      v-if="$vuetify.breakpoint.smAndDown"
    ></SubMenuAnalysisMobile>
    <v-container
      :fluid="$vuetify.breakpoint.lgAndDown"
      style="max-width: 1440px"
    >
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>

          <SubMenuAnalysis></SubMenuAnalysis>


              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col>
                     <GlobalSideBannerBottom></GlobalSideBannerBottom>
                </v-col>
              </v-row>


        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="9"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-0 pt-0' : ''"
        >
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
            <v-divider></v-divider>
          </v-container>

              <v-row class="my-3 mx-0" no-gutters style="background: #fff">
                <v-col>
                     <GlobalSubBannerBottom></GlobalSubBannerBottom>
                </v-col>
              </v-row>

          <v-row class="mt-5" dense 
            v-if="latestLottoData3">
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
            <div
              style="
                position: relative;
                border: 1px solid #f0f0f0;
                border-radius: 4px;
                height: 65px;
              ">
              <v-img
                contain
                src="@/assets/bg_lotto_winner.png"
                style="position: absolute; left: 10px; top: 0; width: 40px"
              />
              <strong
                style="
                  position: absolute;
                  left: 10px;
                  top: 10px;
                  width: 40px;
                  color: #fff;
                  font-size: 12px;
                  text-align: center;
                  line-height: 1;
                "
                >{{latestLottoData3[0].drwNo}}<br /><small>회</small></strong
              >
              <small
                style="
                  position: absolute;
                  right: 10px;
                  top: 12px;
                  color: #888;
                  font-size: 12px;
                  text-align: center;
                  line-height: 1;
                "
                >직전 회차 당첨금액</small
              >
              <strong
                style="
                  position: absolute;
                  right: 10px;
                  bottom: 10px;
                  color: #333;
                  font-size: 20px;
                  text-align: center;
                  line-height: 1;
                " class="half_line"
                >{{$addCommas(latestLottoData3[0].firstWinamnt)}}원</strong
              >
              </div>
            </v-col>



            
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
            <div
              style="
                position: relative;
                border: 1px solid #f0f0f0;
                border-radius: 4px;
                height: 65px;
              ">
              <v-img
                contain
                src="@/assets/bg_lotto_winner.png"
                style="position: absolute; left: 10px; top: 0; width: 40px"
              />
              <strong
                style="
                  position: absolute;
                  left: 10px;
                  top: 10px;
                  width: 40px;
                  color: #fff;
                  font-size: 12px;
                  text-align: center;
                  line-height: 1;
                "
                >{{latestLottoData3[1].drwNo}}<br /><small>회</small></strong
              >
              <small
                style="
                  position: absolute;
                  right: 10px;
                  top: 12px;
                  color: #888;
                  font-size: 12px;
                  text-align: center;
                  line-height: 1;
                "
                >2회차 전,  당첨금액</small
              >
              <strong
                style="
                  position: absolute;
                  right: 10px;
                  bottom: 10px;
                  color: #333;
                  font-size: 20px;
                  text-align: center;
                  line-height: 1;
                " class="half_line"
                >{{$addCommas(latestLottoData3[1].firstWinamnt)}}원</strong
              >
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
            <div
              style="
                position: relative;
                border: 1px solid #f0f0f0;
                border-radius: 4px;
                height: 65px;
              ">
              <v-img
                contain
                src="@/assets/bg_lotto_winner.png"
                style="position: absolute; left: 10px; top: 0; width: 40px"
              />
              <strong
                style="
                  position: absolute;
                  left: 10px;
                  top: 10px;
                  width: 40px;
                  color: #fff;
                  font-size: 12px;
                  text-align: center;
                  line-height: 1;
                "
                >{{latestLottoData3[2].drwNo}}<br /><small>회</small></strong
              >
              <small
                style="
                  position: absolute;
                  right: 10px;
                  top: 12px;
                  color: #888;
                  font-size: 12px;
                  text-align: center;
                  line-height: 1;
                "
                >3회차 전, 당첨금액 </small
              >
              <strong
                style="
                  position: absolute;
                  right: 10px;
                  bottom: 10px;
                  color: #333;
                  font-size: 20px;
                  text-align: center;
                  line-height: 1;
                " class="half_line"
                >{{$addCommas(latestLottoData3[2].firstWinamnt)}}원</strong
              >
              </div>
            </v-col>


          </v-row>

          <v-sheet min-height="58vh" class="text-center">
            <!--  -->

            <v-img
              alt="Vuetify Logo"
              class="shrink rounded-lg mt-5"
              contain
              src="@/assets/tmp_2021_goldsystem.png"
              full-width
            />

            <v-row class="mt-5" no-gutters>
              <v-col cols="12" sm="6" md="6">

                <v-sheet 
                  outlined
                  color="grey lighten-4"
                  class="pa-2 mx-auto"
                  style="
                    font-size: 14px;
                    font-weight: bold;
                    border-radius: 6px;
                    color: #888;
                    text-align: center;
                  "
                  >
                  이번 주 남은 행운 번호 받기 <br>
                  <strong style="font-size: 20px; color: #595d69">{{$store.state.systemOption.uid_chance}}</strong>
 /
{{$store.state.systemOption.service_weeks_get_count}}
                  

                </v-sheet>



                <v-sheet v-if="false"
                  outlined
                  color="grey lighten-4"
                  class="pa-2 mx-auto"
                  style="
                    font-size: 14px;
                    font-weight: bold;
                    border-radius: 6px;
                    color: #888;
                    text-align: center;
                  "
                  >보유포인트 :
                  <strong style="font-size: 20px; color: #595d69">1,000</strong>
                  <br />

                  <v-btn
                    small
                    color="blue-grey"
                    class="ma-2 white--text"
                    @click="loader = 'loading3'"
                  >
                    포인트 충전
                  </v-btn>
                </v-sheet>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="text-center">
                <v-btn
                :disabled="$store.state.systemOption.uid_chance<1"
                  color="blue darken-4 white--text mt-2"
                  x-large
                  class="font-weight-bold"
                  @click="createLottoNums(20)"
                >
                  오늘의 행운 20수 받기 </v-btn
                ><br />
              </v-col>
            </v-row>

            <p class="text--disabled my-5">
              <small>
                당사의 분석시스템은 누적패턴을 분석/필터링한 정보제공만을
                목적으로 하며, 당첨확률 개선서비스가 아니므로 서비스 이용
                과정에서 기대이익을 얻지 못하거나 발생한 손해 등에 대한
                최종책임은 서비스 이용자 본인에게 있습니다.
              </small>
            </p>

<div v-if="false"> 
                <v-btn
                  color="blue darken-4 white--text mt-2"
                  x-large
                  class="font-weight-bold"
                  @click="autoLottoNumbers(1041,1041)"
                >
                  자동채우기 1041 </v-btn
                >
                <v-btn
                  color="blue darken-4 white--text mt-2"
                  x-large
                  class="font-weight-bold"
                  @click="autoLottoResults(1,1041)"
                >
                  당첨금액채우기 </v-btn
                ><br />
</div>
            
            <v-row class="my-0 mx-0" style="background: #fff">
              <v-col>
                    <GlobalSubBannerTop></GlobalSubBannerTop>
              </v-col>
            </v-row>

            <div>
              <LottoResult
                v-if="resultNumbers.length > 0"
                class="mt-10"
              ></LottoResult>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
export default {
  name: "Analysis",
  title: "20수 번호 받기",

  components: {},
  data: () => ({
    checkNums: null,
    e1: 1,

    breadcrumbs: [
      { text: "홈" },
      { text: "로또분석 시스템" },
      { text: "20수 번호 받기" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],

    lottoNumbers: null,

    fixednumbers7: "5,11,13,32,33,36,41",
    lottoNumbers2: null,

    fixednumbers8: "5,11,13,32,33,36,41,44",
    lottoNumbers3: null,

    amenities: [1, 4],


    latestLottoData3 : null,
  }),
  computed: {
    resultNumbers: {
      get() {
        return this.$store.state.resultNumbers;
      },
      set(payload) {
        this.$store.commit("UPDATE_RESULT_NUMBERS", payload);
      },
    },
  },
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getLatestResult();    
  },

  watch: {
    selectedItem() {
      //alert(this.selectedItem);
      //this.createLottoNums2 = [];
      //this.createLottoNums3 = null;

      this.$store.commit("UPDATE_SELECT_NUMBERS", []);
      this.$store.commit("UPDATE_RESULT_NUMBERS", []);

      


    },
    resultNumbers(){
    this.$getServerInfo(this.$store.state.systemOption.uid);

    },
  },
  methods: {

    async autoLottoResults(start,end) {
      const self = this;
      console.log(start,end);

      for (let i = start; i <= end; i++){
          console.log(i); // 0~9까지 출력

            await self.$axios
              .get("https://lotto7777.whynot.so/lottoapp/getgameresult_drwNo.php")
              .then((res) => {
                console.log(res);

                if (res.status === 200) {
                  //this.paging = res.data.paging;
                  //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

                  //this.classes = res.data;
                  //this.lottoNumbers = res.data;
                  //self.resultNumbers = res.data;
                } else {
                  alert("실행중 실패했습니다.\n다시 이용해 주세요.");
                }

                if (self.firstLoad) self.firstLoad = false;
                self.loading = false;
              })
              .then((err) => {
                console.log(err);

                self.loading = false;
              });

          await self.$sleep(1000);
      }

    },

    
    async autoLottoNumbers(start,end) {
      const self = this;
      console.log(start,end);

      for (let i = start; i <= end; i++){
          console.log(i); // 0~9까지 출력

            await self.$axios
              .get("//lotto7777.whynot.so/lottoapp/?mode=create&count=20"+`&uid=${self.$store.state.systemOption.uid}&drwNo=${i}`)
              .then((res) => {
                console.log(res);

                if (res.status === 200) {
                  //this.paging = res.data.paging;
                  //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

                  //this.classes = res.data;
                  //this.lottoNumbers = res.data;
                  //self.resultNumbers = res.data;
                } else {
                  alert("실행중 실패했습니다.\n다시 이용해 주세요.");
                }

                if (self.firstLoad) self.firstLoad = false;
                self.loading = false;
              })
              .then((err) => {
                console.log(err);

                self.loading = false;
              });

          await self.$sleep(1000);
      }

    },


    setSubMenu(no) {
      this.selectedItem = no;
    },

    createLottoNums() {
      this.$axios
        .get("//lotto7777.whynot.so/lottoapp/?mode=create&count=20"+`&uid=${this.$store.state.systemOption.uid}`)
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
            //this.lottoNumbers = res.data;
            this.resultNumbers = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });
    },
    createLottoNums2() {
      console.log(this.$store.state.selectedNumbers.length);
      console.log(this.max);

      if (this.$store.state.selectedNumbers.length != 7) {
        this.$store.commit("pop", {
          msg: "고정 수 조합 7개를 모두 선택해주세요.",
          color: "",
        });
        return false;
      }

      //alert(this.$store.state.selectedNumbers.join(','));
      this.$axios
        .get(
          "//lotto7777.whynot.so/lottoapp/absolute_number.php?includeNumbers=" +
            this.$store.state.selectedNumbers.join(",")
        )
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
            //this.lottoNumbers2 = res.data;

            this.resultNumbers = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });
    },
    createLottoNums3() {
      if (this.$store.state.selectedNumbers.length != 8) {
        this.$store.commit("pop", {
          msg: "고정 수 조합 8개를 모두 선택해주세요.",
          color: "",
        });
        return false;
      }

      this.$axios
        .get(
          "//lotto7777.whynot.so/lottoapp/absolute_number.php?includeNumbers=" +
            this.fixednumbers8
        )
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
            //this.lottoNumbers3 = res.data;

            this.resultNumbers = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });
    },




    getLatestResult() {

      this.$axios
        .get(
          "//lotto7777.iwinv.net/lottoapp/?drwNo=all&mode=get&order=desc&limit=3"
        )
        .then((res) => {
          console.log(res);

          this.latestLottoData3 = res.data;


/*
          this.lottoNumbers = [
            this.latestLottoData['drwtNo1'],
            this.latestLottoData['drwtNo2'],
            this.latestLottoData['drwtNo3'],
            this.latestLottoData['drwtNo4'],
            this.latestLottoData['drwtNo5'],
            this.latestLottoData['drwtNo6']
            ];

          this.lottoBonus = this.latestLottoData['bnusNo'];
*/


        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

    },




  },
};
</script>
<style>
.asdf {
  color: #85ff4d;
}
</style>
